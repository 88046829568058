<template>
    <div class="education-contain">
        <el-scrollbar class="contain-box">
            <div class="top-box">
                <left-tab/>
                <div class="top-title">
                    <div class="title-box">
                        <div class="title-text">{{title}}</div>
                        <div class="title-info">{{description}}</div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="main-box-contain box1">
                    <div class="top-card">
                        <div class="top-card-contain">
                            <div class="left-card-box">
                                <div class="card-title">平台概述</div>
                                <div class="divide-line">
                                    <div class="left-line"></div>
                                    <div class="right-line"></div>
                                </div>
                                <div class="card-text">
                                    随着电子商务的高速发展，企业对电子商务人才技能要求越来越高，传统的理论教学难以跟上时代的步伐，所以我们将实训软件与课程资源进行完美融合，进而打造边学边做的一站式实训教学模式，能够大大提升教师的实战教学质量，提升学生的综合实践能力。
                                </div>
                            </div>
                            <div class="right-card-box">
                                <div class="img-box">
                                    <img src="../../../assets/images/education/s-right.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-card">
                        <div class="box-title">平台特色</div>
                        <div class="card-item-box">
                            <div class="card-item" v-for="item in cardList" :key="item.image">
                                <div class="card-img" :style="{backgroundImage: 'url(' + (item.background) + ')'}">
                                    <img :src="item.image" alt="">
                                </div>
                                <div class="card-item-title">{{item.title}}</div>
                                <div class="card-item-content">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-box-contain box2">
                    <div class="top-box-content">
                        <div class="box-title">核心功能</div>
                        <div class="box-title-describe">软件与实训内容结合，以电商实战为出发点，以技能训练为核心，通过多个环节手把手的训练，提升技能水平</div>
                        <div class="title-icon-box">
                            <div :class="currentActive === item.id?'icon-item active':'icon-item'"
                                 v-for="item in resourceList">
                                <div class="item-content" @click="changeShow(item)">
                                    <div class="img-box"
                                         :style="{background:item.style}">
                                        <img :src="item.image" alt="">
                                    </div>
                                    <div class="item-title">{{item.name}}</div>
                                    <div v-show="currentActive===item.id" class="item-content-text">{{item.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-box">
                        <img :src="cardContent.show" alt="">
                    </div>
                    <div class="bottom-box-content">
                        <div class="title-icon-box">
                            <div :class="currentActive === item.id?'icon-item active':'icon-item'"
                                 v-for="item in resourceList1" @click="changeShow(item)">
                                <div class="item-content">
                                    <div class="img-box"
                                         :style="{background:item.style}">
                                        <img :src="item.image" alt="">
                                    </div>
                                    <div class="item-title">{{item.name}}</div>
                                    <div v-show="currentActive===item.id" class="item-content-text">{{item.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-box-contain box3">
                    <div class="banner-back"></div>
                    <div class="top-box-content">
                        <div class="box-title">一课电子商务专业系列软件</div>
                        <div class="middle-box">
                            <div v-swiper:mySwiper="bannerOption" class="home-banner">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" :key="bannerItem.id" v-for="bannerItem in bannerList">
                                        <img :src="bannerItem.banner_img" class="banner-img">
                                        <div class="banner-content">
                                            <div class="box-title">{{bannerItem.title}}</div>
                                            <div class="box-text">{{bannerItem.content}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                                <div class="banner-swiper-btn-prev">
                                    <div class="change-btn">
                                        <i class="el-icon-arrow-left"></i>
                                    </div>
                                </div>
                                <div class="banner-swiper-btn-next">
                                    <div class="change-btn">
                                        <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import leftTab from "@/components/education/leftTab";
    import OfficialFooter from '@/components/OfficialFooter';
    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        name: "Simulation",
        components: {
            leftTab,
            OfficialFooter,
            Swiper,
            SwiperSlide,
        },
        directives: {
            swiper: directive
        },
        data() {
            return {
                title: '仿真实训平台',
                description: "将实训软件与课程资源进行完美融合，进而打造边学边做的一站式实训教学模式，大大提升教师的实战教学质量，提升学生的综合实践能力。",
                cardList: [
                    {
                        image: require('../../../assets/images/education/s-card1.png'),
                        title: '双重性格，灵活多变',
                        content: '不仅具备强大的实战功能，构建学校电商销售平台；对于难于实战部分增加了仿真实训设计实训与实战的完美结合',
                        background: require('../../../assets/images/education/s-card1-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/s-card2.png'),
                        title: '互通互联，打造一站式实训平台',
                        content: '将电商不同模式软件互相贯穿、数据打通，一个账号通行所有软件，更能够与课程体系完美结合， 打造真正的一站式教学实训一体化平台',
                        background: require('../../../assets/images/education/s-card2-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/s-card3.png'),
                        title: '软件与课程完美结合',
                        content: '软件与课程完美结合，每个任务做到理实一体化教学，边学边做，解决实训无法落地的痛点',
                        background: require('../../../assets/images/education/s-card3-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/s-card4.png'),
                        title: '功能强大，仿真度高',
                        content: '软件功能强大，具备主流平台核心功能，如淘宝头条、聚划算等，能够保证实训实战效果',
                        background: require('../../../assets/images/education/s-card4-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/s-card5.png'),
                        title: '即时更新',
                        content: '实训软件根据主流平台进行每周更新，不仅增加了内容、社交、直播等最新功能，同时增加了各类有效的插件，让实训更丰富，让实践更灵活',
                        background: require('../../../assets/images/education/s-card5-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/s-card6.png'),
                        title: '完善的教学管理和评价体系',
                        content: '强大的教学管理与评价功能，方便教师进行实管理',
                        background: require('../../../assets/images/education/s-card6-back.png')
                    },
                ],
                currentActive: 1,
                resourceList: [
                    {
                        id: 1,
                        name: '商品模块',
                        image: require('../../../assets/images/education/product.png'),
                        style: 'linear-gradient(99deg, #F9C556 0%, #FC6A50 100%)',
                        content: '商品模块除了具备核心的商品管理、商品导入、商品页模板、商品分类、我要分销等，我们增加了独特的主图视频、手机详情页、一键导入淘宝客货源等特色功能。',
                        show: require('../../../assets/images/education/show-product.png')
                    },
                    {
                        id: 2,
                        name: '支付模块',
                        image: require('../../../assets/images/education/pay.png'),
                        style: 'linear-gradient(87deg, #64DFEC 0%, #64B8F1 100%)',
                        content: '支持常用的银联、支付宝、微信等实战支付插件外，具体提现、购物卡、储值资金、对账单等功能外，我们增加积分支付手段，方便学生使用积分做好如直通车等仿真实训。',
                        show: require('../../../assets/images/education/show-pay.png')
                    },
                    {
                        id: 3,
                        name: '物流模块 ',
                        image: require('../../../assets/images/education/delivery.png'),
                        style: 'linear-gradient(123deg, #F6C5FA 0%, #6F18F2 100%)',
                        content: '除了具备常用物流自动处理、订单分类、退款维权、订单分析等功能外，订单模块加入快速打单、以及线上线下订单汇总等功能。',
                        show: require('../../../assets/images/education/show-delivery.png')
                    },
                ],
                resourceList1: [
                    {
                        id: 4,
                        name: '装修模块',
                        image: require('../../../assets/images/education/decoration.png'),
                        style: 'linear-gradient(108deg, #F3636F 0%, #F93294 100%)',
                        content: '店铺装修直接决定了店铺视觉与呈现，为了增加装修的趣味性与多样性，我们采取模块拖拽式的装修设计，能够直观且傻瓜式进行装修设计，提升店铺装修技能。',
                        show: require('../../../assets/images/education/show-decoration.png')
                    },
                    {
                        id: 5,
                        name: '数据模块',
                        image: require('../../../assets/images/education/data.png'),
                        style: 'linear-gradient(87deg, #34D8AB 0%, #37D185 100%)',
                        content: '数据分析能力是电商专业学生必备的核心技能，我们通过数据概况、粉丝分析、页面流量、来源控制、商品分析、交易分析、扫码统计、卡券统计等模块的实训来提升数据分析能',
                        show: require('../../../assets/images/education/show-data.png')
                    },
                    {
                        id: 6,
                        name: '营销模块',
                        image: require('../../../assets/images/education/marketing.png'),
                        style: 'linear-gradient(87deg, #F8B65A 0%, #F8897D 100%)',
                        content: '营销模块分为促销与营销两个部分，促销通过数十种促销插件，如团购、满即送等提升店铺活动运营能力，而营销我们通过站内营销与站外营销大量的插件提升店铺营销能力。',
                        show: require('../../../assets/images/education/show-markting.png')
                    },
                ],
                cardContent: {
                    show: require('../../../assets/images/education/show-product.png')
                },
                bannerList: [
                    {
                        id: 1,
                        banner_img: require('../../../assets/images/education/scroll2.png'),
                        title: '新媒体实战教学平台',
                        content: '新媒体实战教学平台是集教学、实训、实战于一体的6大系统1个大数据看板系统。包含新媒体运营大数据分析系统、文案创作分析系统、脚本制作系统、短视频云剪辑系统、实战项目系统、货源系统、运营数据看板功能的理实一体化平台，方便老师发布任务、对学生新媒体实训、实战提供便捷的帐号管理、内容发布、内容管理、数据统计等功需求。平台采用B/S架构，搭建云服务器，师生可以随时随地使用，不受地点和时间的限制，并与优酷、爱奇艺、今日头条、企鹅媒体、网易媒体、新浪微博、秒拍等20余家主流新媒体平台合作，获取实时数据。满足学校对新媒体实战培养的需求，可作为企业招选新媒体人才的参考依据，为学校与企业合作奠定了坚实的基础。'
                    },
                    {
                        id: 2,
                        banner_img: require('../../../assets/images/education/scroll3.png'),
                        title: 'C2C实战教学系统',
                        content: 'C2C实战教学系统软件是根据淘宝网电商平台创建店铺形式呈现，既能进行项目实训教学又能在平台进行开店实战项目。平台能实训到淘宝网从店铺开通、店铺装修、宝贝发布、店铺营销、订单、物流管理、客户CRM等真实淘宝网开店环节，到淘宝网最新的内容电商、淘宝达人、主图视频、双11活动报名、聚划算、天天特价等。为学校提供一个电商实训、实战平台。'
                    },
                    {
                        id: 3,
                        banner_img: require('../../../assets/images/education/scroll4.png'),
                        title: '电商创客实战平台',
                        content: '电商创客实战平台是根据社交电商所需要的微信公众号、小程序打造开发的。学生无需学习软件开发，可一键应用平台21套小程序及微信公众号应用如拼团（拼多多）、外卖（美团）、卡券、社区服务、便利店、线上店铺O2O、酒店、KTV、汽修等，让学生发挥自己快速上手做新零售实战创业。'
                    },
                    {
                        id: 4,
                        banner_img: require('../../../assets/images/education/scroll5.png'),
                        title: 'B2B实战教学系统',
                        content: 'B2B实战教学系统是根据阿里1688研发，采用批发模式，有起批价、分销、阶梯价，团购报名，限时促销等功能。系统具有买家端、卖家端、教师端，管理员端，为学校提供一个能够进行真实的交易支付的商城平台，平台融合强大的货源系统以及教学功能，并支持PC与移动前端装修、修改。'
                    },
                    {
                        id: 5,
                        banner_img: require('../../../assets/images/education/scroll6.png'),
                        title: 'B2B2C实战教学系统',
                        content: 'B2B2C实战教学系统软件V1.0是根据京东商城采用自营+店铺平台设计结构，既能够进行项目实训教学又能将自营平台进行实战的商城平台系统。系统具有买家端、卖家端、自营商城、教师端，为学校提供一个能够进行真实的交易支付的商城平台，平台融合强大的货源系统以及教学功能。系统综合最新的电商自媒体平台与O2O门店管理平台，并支持PC与移动前端装修、修改。'
                    },
                    {
                        id: 6,
                        banner_img: require('../../../assets/images/education/scroll7.png'),
                        title: '跨境B2B2C实战教学系统',
                        content: '跨境B2B2C实战教学系统软件是根据亚马逊（AMAZON）电商平台创建的，既是商城系统又是多店铺系统，具备直营与开店功能。平台具备亚马逊核心功能，如UI界面、跟卖、REVIEWS外，我们进行了改良，让店铺展现更加轻松，店铺可以自己进行店铺装修与设计，打造自己品牌。平台能实训到亚马逊从店铺开通、店铺装修、宝贝发布、店铺营销、订单、物流管理、客户CRM等真实亚马逊开店环节，到亚马逊活动报名、banner展位报名等基础功能。同时，该系统提供了更多特色功能，如Deals Week(每周促销)、Recommended deals（促销推荐）、Today\'s hottest deals（热门促销）、Best sellers（热卖商品）、Gift cards(礼品卡)等。学校不仅可以此平台进行实训教学，同时还可以对接支付进行跨境实战。'
                    },
                    {
                        id: 7,
                        banner_img: require('../../../assets/images/education/scroll8.png'),
                        title: '跨境B2C实战教学系统',
                        content: '跨境B2C实战教学系统软件是根据速卖通平台创建店铺形式呈现，既能进行项目实训教学又能在平台进行开店实战项目。平台能实训到速卖通从店铺开通、店铺装修、宝贝发布、店铺营销、订单、物流管理、客户CRM等真实速卖通开店环节，到速卖通活动报名、banner展位报名等基础功能。同时，该系统提供了更多特色功能，如GROUPBUY(团购)、PROMOTIONS（促销）、POINTS（积分）、SPECIAL(特卖)等。学校不仅可以此平台进行实训教学，同时还可以对接支付进行跨境实战。'
                    },
                    {
                        id: 8,
                        banner_img: require('../../../assets/images/education/scroll9.png'),
                        title: '跨境B2B实战教学系统',
                        content: 'B2B实战教学系统是根据阿里巴巴国际站研发，采用批发模式，有起批价、分销、询盘、阶梯价，团购报名，限时促销等功能。系统具有买家端、卖家端、教师端，管理员端，为学校提供一个能够进行真实的交易支付的商城平台，平台融合强大的货源系统以及教学功能，并支持PC与移动前端装修、修改。'
                    },
                    {
                        id: 9,
                        banner_img: require('../../../assets/images/education/scroll10.png'),
                        title: '新零售门店管理系统',
                        content: '2017年阿里巴巴不再提电商，转而大力推广线上线下融合的新零售。新零售门店管理系统软件V1.0是在新零售势必发展的大趋势下开发而成的，希望打造通过O2O门店管理系统对接线上线下资源，线上线下资源共享、优势互补，打造校园新零售生态。该系统包含三大核心模块：线上商城、小邮局物流系统以及门店管理系统。该系统对接O2O线下体验馆，不仅可以帮助学校打造门店连锁实训环境，同时真正通过线上线下运营活动盘活学校电商生态，打造校园线上线下商城系统。'
                    },
                    {
                        id: 10,
                        banner_img: require('../../../assets/images/education/scroll11.png'),
                        title: 'B2C实战教学系统',
                        content: 'B2C实战教学系统软件是根据目前主流的B2C平台商城（天猫、天猫国际等）进行开发，是多商户商城系统，能够方便品牌企业等在平台开设旗舰店、专营店等。平台能实训到从店铺开通、店铺装修、宝贝发布、品牌展示、活动运营、店铺营销、订单、物流管理、客户CRM等真实商城开店环节，以及能够适用最新的内容电商、天猫达人、主图视频、双11活动报名、聚划算等。为学校提供一个电商实训、实战平台。'
                    },
                ],
                bannerOption: {
                    autoplay: true,
                    initialSlide: 1,
                    autoHeight: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents: true,
                },
            }
        },
        methods: {
            changeShow(item) {
                this.currentActive = item.id;
                this.cardContent.show = item.show;
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-contain {
        height: 100%;
        width: 100%;

        .contain-box {
            height: 100%;
            width: 100%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .top-box {
            width: 100%;
            height: 466px;
            position: relative;
            background-image: url("../../../assets/images/education/simulation_platform.png");
            background-repeat: no-repeat;
            background-size: cover;

            .top-title {
                width: 1150px;
                margin: 0 auto;
                padding-top: 110px;

                .title-box {
                    padding: 0 350px 0 110px;

                    .title-text {
                        font-size: 45px;
                        color: #FFFFFF;
                    }

                    .title-info {
                        width: 660px;
                        line-height: 30px;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin-top: 40px;
                    }
                }
            }
        }

        .main-box {
            width: 100%;

            .main-box-contain {
                background-image: url("../../../assets/images/education/circle.png");
                background-repeat: repeat;
                background-size: cover;

                .box-title {
                    text-align: center;
                    font-size: 30px;
                    color: #333333;
                }

                &.box1 {
                    background-image: url("../../../assets/images/education/circle.png"), url("../../../assets/images/education/purple_back.png");
                    background-repeat: no-repeat no-repeat;
                    background-size: cover, contain;
                    position: relative;

                    .top-card {
                        width: 1150px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 10px 0px rgba(8, 51, 149, 0.1);
                        border-radius: 10px;
                        height: 397px;
                        position: absolute;
                        top: -100px;
                        left: 50%;
                        transform: translate3d(-50%, 0, 0);

                        .top-card-contain {
                            display: flex;
                            height: 100%;

                            .left-card-box {
                                padding: 30px 50px 30px 90px;

                                .card-title {
                                    font-size: 30px;
                                    color: #333333;
                                }

                                .divide-line {
                                    margin-top: 27px;
                                    display: flex;

                                    .left-line {
                                        width: 30px;
                                        height: 2px;
                                        background: #584EEE;
                                    }

                                    .right-line {
                                        width: 30px;
                                        height: 2px;
                                        background: #F36F21;
                                    }
                                }

                                .card-text {
                                    margin-top: 40px;
                                    width: 580px;
                                    font-size: 16px;
                                    color: #333333;
                                    line-height: 48px;
                                }

                            }

                            .right-card-box {
                                flex: 1;
                                background-image: url("../../../assets/images/education/s-right-back.png");
                                background-repeat: no-repeat;
                                background-size: cover;

                                .img-box {
                                    padding-left: 150px;
                                    padding-top: 60px;
                                }
                            }
                        }
                    }

                    .bottom-card {
                        width: 1150px;
                        margin: 0 auto;
                        height: 700px;
                        padding-top: 450px;
                        padding-bottom: 50px;

                        .card-title {
                            text-align: center;
                            font-size: 28px;
                            color: #333333;
                        }

                        .card-item-box {
                            display: flex;
                            flex-wrap: wrap;

                            .card-item {
                                margin-top: 40px;
                                width: calc(33% - 10px);
                                height: 292px;
                                background: #FFFFFF;
                                box-shadow: 0px 2px 10px 0px rgba(8, 51, 149, 0.1);
                                border-radius: 10px;
                                margin-right: 20px;

                                &:hover {
                                    margin-top: 25px;
                                    transition: 0.5s;
                                }

                                &:nth-of-type(3n) {
                                    margin-right: 0;
                                }

                                &:first-child {
                                    .card-img {
                                        height: 137px;
                                    }
                                }

                                &:nth-of-type(2) {
                                    .card-img {
                                        width: 212px;
                                    }
                                }

                                &:nth-of-type(3) {
                                    .card-img {
                                        width: 202px;
                                    }
                                }
                            }

                            .card-img {
                                text-align: center;
                                width: 172px;
                                height: 120px;
                                margin: 0 auto;

                                img {
                                    margin-top: 40px;
                                }
                            }

                            .card-item-title {
                                text-align: center;
                                font-size: 16px;
                                color: #222222;
                                margin-top: 10px;
                            }

                            .card-item-content {
                                width: 310px;
                                height: 39px;
                                font-size: 14px;
                                font-weight: 300;
                                color: #666666;
                                line-height: 24px;
                                margin: 40px auto;
                            }
                        }
                    }
                }

                &.box2 {
                    background-color: #FFFFFF;

                    .title-icon-box {
                        width: 1100px;
                        margin-top: 40px;
                        display: flex;

                        .icon-item {
                            width: 280px;
                            height: 360px;
                            margin-right: 150px;
                            cursor: pointer;

                            &:nth-of-type(3) {
                                margin-right: 0;
                            }

                            &.active {
                                box-shadow: 0px 0px 13px 0px rgba(5, 69, 165, 0.15);
                                border-radius: 20px;
                                transition: 0.5s;

                                .item-content {

                                    .img-box {
                                        width: 100%;
                                        height: 140px;
                                        border: unset;
                                        border-radius: 20px 20px 0 0;
                                    }

                                    .item-title {
                                        font-size: 18px;
                                    }
                                }

                            }

                            .item-content {
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                .img-box {
                                    width: 110px;
                                    height: 110px;
                                    border: 1px solid #DDDDDD;
                                    border-radius: 50%;
                                    text-align: center;
                                }

                                .item-title {
                                    font-size: 12px;
                                    margin-top: 10px;
                                }

                                .item-content-text {
                                    width: 200px;
                                    margin-top: 30px;
                                    font-size: 12px;
                                    font-weight: 300;
                                    color: #666666;
                                    line-height: 24px;
                                    text-indent: 2em;
                                }
                            }
                        }
                    }

                    .top-box-content {
                        width: 1100px;
                        margin: 0 auto;
                        padding-top: 100px;
                        padding-bottom: 100px;

                        .box-title-describe {
                            font-size: 16px;
                            color: #666666;
                            line-height: 24px;
                            text-align: center;
                            margin-top: 40px;
                        }
                    }

                    .bottom-box-content {
                        width: 1100px;
                        margin: 0 auto;
                        padding-top: 100px;
                        padding-bottom: 200px;
                    }

                    .main-box {
                        width: 836px;
                        height: 462px;
                        margin: 0 auto;
                        background-image: url("../../../assets/images/education/blank-pc.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: relative;

                        img {
                            position: absolute;
                            top: 26px;
                            left: 96px;
                        }
                    }
                }

                &.box3 {
                    background-color: #F7FAFB;
                    background-image: url("../../../assets/images/about/lift-hand.jpg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: bottom;
                    position: relative;

                    .banner-back {
                        width: 100%;
                        position: absolute;
                        height: 332px;
                        background: linear-gradient(112deg, #2820C0, #9459EC);
                        opacity: 0.8;
                        top: 20%;
                    }

                    .top-box-content {
                        width: 1200px;
                        margin: 0 auto;
                        padding-top: 100px;

                        .middle-box {

                            .home-banner {
                                width: 100%;


                                .banner-content {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;

                                    .box-text {
                                        margin-top: 50px;
                                        font-size: 16px;
                                        color: #666666;
                                        line-height: 24px;
                                        width: 728px;
                                        height: 300px;
                                    }
                                }

                                ::v-deep .swiper-pagination {
                                    display: none;
                                }

                                .banner-swiper-btn-prev,
                                .banner-swiper-btn-next {
                                    width: 10%;
                                    position: absolute;
                                    top: 25%;
                                    bottom: 0;
                                    display: flex;
                                    justify-content: center;
                                    z-index: 10;
                                    cursor: pointer;
                                    color: #655BEF;

                                    .change-btn {
                                        width: 50px;
                                        height: 80px;
                                        background: #FFFFFF;
                                        box-shadow: 0px 0px 13px 0px rgba(5, 69, 165, 0.15);
                                        border-radius: 10px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        i {
                                            font-size: 24px;
                                        }
                                    }
                                }

                                .banner-swiper-btn-prev {
                                    left: 0;
                                }

                                .banner-swiper-btn-next {
                                    right: 0;
                                }
                            }
                        }

                        img {
                            padding: 150px 100px;
                        }
                    }
                }
            }
        }
    }
</style>